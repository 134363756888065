import React from 'react'
import styles from './EventsToolPanel.module.css'


const EventsToolPanel = () => {
  return (
    <div className={styles.knockingPanel}>
      <div className={styles.shapeContainer}>
        <h3>COUNTIES</h3>

      </div>
    </div>
  )
}

export default EventsToolPanel
